import React from "react";

interface IConditionalWrappersProps {
  condition: string;
  condition2: string;
  wrapper: React.FC<any>;
  wrapper2: React.FC<any>;
  children: React.ReactNode;
}

export const ConditionalWrappers: React.FC<IConditionalWrappersProps> = ({condition, condition2, wrapper, wrapper2, children}: IConditionalWrappersProps) => {
  return condition ? wrapper(children) : condition2 ? wrapper2(children) : children as React.ReactElement
};


export default ConditionalWrappers;