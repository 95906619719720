import { Link } from "gatsby";
import styled from "styled-components";

export const Nav = styled.nav`
  background: var(--background);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  @media screen and (max-width: 1199px) {
    padding: 0.5rem 0;
    height: 116px;
  }

`;

export const Logo = styled.img`
  height: 150px;
  @media screen and (max-width: 1199px) {
    height: 116px;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  width: 77%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  transition: all 0.3s ease-out;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  @media screen and (max-width: 1530px) {
    width: 80%;
  }
  @media screen and (max-width: 1199px) {
    position: absolute !important;
    top: 116px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    box-shadow: 0px 5px 15px rgba(60, 63, 73, 0.06);
    max-height: 0px;
    overflow: hidden;
    z-index: 999;
    background-color: var(--background);
  }

`;
export const MenuCenter = styled.div`
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 1199px) {
    background-color: #fff;
    flex-direction: column;
    text-align: center;
  }
`;

export const NavLink = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    padding: 1rem 0;
    &:first-of-type {
      padding-top: 1.5rem;
    }
    &:last-of-type {
      padding-bottom: 1.5rem;
    }
  }
`;

export const CustomLink = styled(Link)`
  color: var(--link);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  padding: 0 20px;
  cursor: pointer;
  transition: color 0.1s ease-in;
  font-family: "Roboto", sans-serif;
  &:hover {
    color: var(--link-hover);
    text-decoration: none;
  }
  @media screen and (max-width: 1699px) {
    font-size: 16px;
    padding: 0 15px;
  }
  @media screen and (max-width: 1399px) {
    font-size: 15px;
    padding: 0 10px;
  }
`;

export const MenuButton = styled.div`
  display: none;
  padding: 10px 0;
  @media screen and (max-width: 1199px) {
    display: flex;
    justify-content: flex-end;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    padding: 20px;
    padding-right: 0;
    img {
      width: 30px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 1199px) {
    margin-bottom: 40px;
  }
`;
