import React from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
export interface ILayoutProps {}

const Layout: React.FC<ILayoutProps> = ({children}) => {
  return (
    <div className="page-wrapper">
      <Navigation />
      {children}
      <Footer/>
    </div>
  );
};

export default Layout;
