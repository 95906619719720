import { Link } from 'gatsby'
import React from 'react'

import ConditionalWrappers from '../../services/Utils/ConditionalWrappers'
import { ButtonCta } from './Button.style'

interface IButtonProps{
  title: string,
  internalLink?: string,
  externalLink?: string,
  padding?: string,
  width?: string
  secondary?: boolean
}

function Button({ title, internalLink, externalLink, padding, width, target, secondary = false}: IButtonProps) {
  return (
    <>
      <ConditionalWrappers
        condition={internalLink || ''} 
        condition2={externalLink || ''}
        wrapper={(children) => (
          <Link to={internalLink!} style={{ width: width || 'fit-content', display: 'block' }}>
            {children}
          </Link>
        )}
        wrapper2={(children) => (
          <a href={externalLink} target={target || '_self'} style={{ width: width || 'fit-content', display: 'block' }}>
            {children}
          </a>
        )}>
        <ButtonCta padding={padding || ''} secondary={secondary}>
          {title}
        </ButtonCta>
      </ConditionalWrappers>
    </>
  )
}

export default Button
