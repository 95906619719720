import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Logo from '../../assets/images/logo-w1000h600.jpg'

interface ISeoProps{
  description?: string,
  lang?: string,
  keywords?: string,
  twitterUsername?: string,
  image?: string,
  title: string,
  pathname?: string,
  article?: boolean,
  imageSrcSet?: string
}

function Seo({
  description,
  lang,
  keywords,
  twitterUsername,
  image: metaImage,
  title,
  pathname,
  article,
  // imageSrcSet
}: ISeoProps) {

  const { site } = useStaticQuery(
    graphql`
      query pageData {
        site {
          siteMetadata {
            siteUrl
            title
            author
          }
        }
      }
    `
  )

  const image = metaImage ? `${site.siteMetadata.siteUrl}${metaImage}` : `${site.siteMetadata.siteUrl}${Logo}`

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'pl',
      }}>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {image && <meta name="image" content={image} />}
      {canonical && <meta property="og:url" content={canonical} />}
      {article && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {/* {imageSrcSet && <link rel="preload" as="image" imagesrcset={imageSrcSet} />} */}
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="author" content={site.siteMetadata.author} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  )
}

export default Seo
