import { Link } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";

import BurgerIcon from "../../assets/images/icons/hamburger.svg";
import LogoSvg from "../../assets/images/ranking-ikze-logo.svg";
import useToggleMenu from "../../hooks/useToggleMenu";
import Button from "../Button/Button";
import { ButtonWrapper, CustomLink, Logo, MenuButton, MenuCenter, Nav, NavLink, NavLinks } from "./Navigation.style";

import config from '../../../config.json'

export interface INavigationProps {}

const Navigation: React.FC<INavigationProps> = () => {
  const [isOpen, setIsOpen] = useToggleMenu();

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <Container className="d-flex justify-content-between align-items-center">
        <Link to="/">
          <Logo src={LogoSvg} />
        </Link>
        <NavLinks className={isOpen ? "active" : ""}>
          <MenuCenter>
            {config.MENU_ITEMS.map((menuItem) => {
              return (
                <NavLink key={menuItem.link}>
                  <CustomLink to={menuItem.link}>{menuItem.title}{menuItem.link === '/limit-wplat-na-ikze/' ? ' ' + new Date().getFullYear() : ''}</CustomLink>
                </NavLink>
              );
            })}
          </MenuCenter>
          <ButtonWrapper>
            <Button
              title="Porównaj oferty IKZE"
              internalLink="/porownanie-ofert-ikze/"
              width="auto"
              padding="10px 15px 10px 15px"
            />
          </ButtonWrapper>
        </NavLinks>

        <MenuButton onClick={handleMenuClick}>
          <img src={BurgerIcon} alt="Menu ikona" title="Menu" />
        </MenuButton>
      </Container>
    </Nav>
  );
};

export default Navigation;
