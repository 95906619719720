import styled from "styled-components";

interface IButtonProps {
  padding: string;
  secondary: boolean
}

export const ButtonCta = styled.button<IButtonProps>`
  color: ${({secondary}) => secondary ? 'var(--primary)' : '#FFF' };
  background-color: ${({secondary}) => secondary ? 'transparent' : 'var(--button-primary)' };
  border-radius: 7px;
  border: 0;
  font-size: 18px;
  padding: ${({padding}) => (padding ? padding : "20px 40px 20px 40px")};
  font-weight: 600;
  transition: all 0.2s ease;
  font-family: "Poppins", sans-serif;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  max-height: 70px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  line-height: "1.4";
  &:hover {
    background-color: ${({secondary}) => secondary ? '' : 'var(--button-primary-hover)' };;
  }
  @media screen and (max-width: 991px) {
    padding: 20px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 16px;
    max-height: none;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 100%;
    width: 100%;
  }
  @media screen and (max-width: 1399px){
      font-size: 16px;
  }
`;
