import { Link } from "gatsby";
import React from "react";

import Logo from "../../assets/images/ranking-ikze-logo.svg";
import './Footer.styles.sass'

import config from '../../../config.json'

const Footer: React.FC = () => {

  return (
    <>
      <footer id="footer" className="py-section bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <img className="logo" src={Logo} alt="Ranking IKZE" />
            </div>
            <div className="col-md-2">
              <ul className="footer-menu">
                <li>
                  <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
                </li>
                <li>
                  <Link to="/regulamin/">Regulamin</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul className="footer-menu">
                {config.MENU_ITEMS.map((item)=>{
                    return <li key={item.link}><Link to={item.link} >{item.title}{item.link === '/limit-wplat-na-ikze/' ? ' ' + new Date().getFullYear() : ''}</Link></li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="top-line">
                <p>Wszystkie prawa zastrzeżone &copy; {new Date().getFullYear()} Ranking IKZE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
